html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hero .inner:after, .hero-circles:after, .hero-circles .circles-container:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.main .centre {
  display: block;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden; }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.hero-circles blockquote cite, .main .home-title h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 700; }

.hero .hero-inner {
  height: auto;
  background: url("/images/banners/home/home-1.jpg") no-repeat top center;
  background-size: auto 500px;
  padding-top: 160px; }
  @media only screen and (min-width: 40em) {
    .hero .hero-inner {
      background: url("/images/banners/home/home-1.jpg") no-repeat center;
      background-size: cover; } }
  @media only screen and (min-width: 50em) {
    .hero .hero-inner {
      height: 800px; } }
  @media only screen and (min-width: 60em) {
    .hero .hero-inner {
      padding-top: 0; } }
  .hero .hero-inner > .inner {
    z-index: 2; }

.home-hero-images {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1; }
  .home-hero-images * {
    height: 100% !important;
    width: 100% !important; }
  .home-hero-images li.slide-1 {
    background: url("/images/banners/home/home-1.jpg") no-repeat center center;
    background-size: cover;
    transition: background .5s ease-out; }
  .home-hero-images li.slide-2 {
    background: url("/images/banners/home/home-2.jpg") no-repeat center center;
    background-size: cover;
    transition: background .5s ease-out; }
  .home-hero-images li.slide-3 {
    background: url("/images/banners/home/home-3.jpg") no-repeat center center;
    background-size: cover;
    transition: background .5s ease-out; }

.hero-circles {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
  padding-top: 60px;
  position: relative; }
  @media only screen and (min-width: 60em) {
    .hero-circles {
      top: 110px;
      padding-top: 0; } }
  .hero-circles blockquote {
    color: #fff;
    font-size: 1.1rem;
    font-family: "Libre Baskerville", "Times New Roman", serif;
    margin: 1.5rem 0; }
    @media only screen and (min-width: 50em) {
      .hero-circles blockquote {
        font-size: 1.4rem; } }
    @media only screen and (min-width: 60em) {
      .hero-circles blockquote {
        font-size: 1.8rem;
        padding-left: 30%; } }
    @media only screen and (min-width: 60em) {
      .hero-circles blockquote {
        margin: 6rem 0 1.5rem; } }
    .hero-circles blockquote cite {
      font-family: "Catamarman", "Helvetica Neue", "Arial", sans-serif;
      display: block;
      font-style: normal;
      font-size: 0.8rem;
      margin-top: 2rem; }
  .hero-circles .circles-container {
    margin-top: 3rem; }
  .hero-circles .circle {
    width: 100%;
    position: relative;
    top: 10%;
    transition: all .3s ease-in-out;
    display: block;
    margin-bottom: 1.5rem;
    float: left;
    height: 70px; }
    @media only screen and (min-width: 40em) {
      .hero-circles .circle {
        width: 50%;
        height: auto;
        border-radius: auto; } }
    @media only screen and (min-width: 50em) {
      .hero-circles .circle {
        width: 25%; } }
    @media only screen and (min-width: 70em) {
      .hero-circles .circle:nth-child(1) {
        top: -80px; }
      .hero-circles .circle:nth-child(2) {
        top: 70px; }
      .hero-circles .circle:nth-child(3) {
        top: -30px; }
      .hero-circles .circle:nth-child(4) {
        top: 40px; } }
    .hero-circles .circle a {
      position: relative;
      display: block;
      color: #fff;
      height: 100%;
      width: 100%;
      text-align: center;
      background: #00c4e0;
      font-size: 0.7rem;
      margin: 0 auto;
      border-radius: 12px; }
      @media only screen and (min-width: 40em) {
        .hero-circles .circle a {
          font-size: 0.8rem;
          border-radius: 50%;
          width: 140px;
          height: 140px;
          box-shadow: 5px 5px 11px 1px rgba(17, 17, 17, 0.2); } }
      @media only screen and (min-width: 50em) {
        .hero-circles .circle a {
          font-size: 0.9rem;
          border-radius: 50%;
          width: 160px;
          height: 160px; } }
      @media only screen and (min-width: 70em) {
        .hero-circles .circle a {
          font-size: 1.1rem;
          border-radius: 50%;
          width: 220px;
          height: 220px; } }
      .hero-circles .circle a:hover {
        transform: scale(1.05);
        background: #ad749c;
        transition: 0.3s; }
        .hero-circles .circle a:hover > span {
          text-decoration: underline; }
      .hero-circles .circle a img {
        width: 16px; }
        @media only screen and (min-width: 50em) {
          .hero-circles .circle a img {
            width: 23px; } }
        @media only screen and (min-width: 70em) {
          .hero-circles .circle a img {
            width: auto; } }
    .hero-circles .circle span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.hero-bottom .hero-services {
  margin-bottom: 0;
  display: block; }
  .hero-bottom .hero-services ul li {
    height: 100px;
    margin-bottom: 1.5rem; }
    @media only screen and (min-width: 60em) {
      .hero-bottom .hero-services ul li {
        height: 300px; } }
    .hero-bottom .hero-services ul li span:after {
      width: 100%;
      height: 60px; }
      @media only screen and (min-width: 60em) {
        .hero-bottom .hero-services ul li span:after {
          width: 250px;
          height: 88px; } }
    .hero-bottom .hero-services ul li:hover span:after {
      width: 110%;
      height: 80px; }
      @media only screen and (min-width: 60em) {
        .hero-bottom .hero-services ul li:hover span:after {
          width: 270px;
          height: 100px; } }

@media only screen and (min-width: 60em) {
  .home-text-rotator-wrapper .bx-controls {
    padding-left: 30%; } }

.home-text-rotator-wrapper .bx-pager-item {
  display: inline-block;
  width: 12px;
  height: 12px;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 50%; }
  .home-text-rotator-wrapper .bx-pager-item a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #fff;
    text-indent: -999em;
    background: rgba(255, 255, 255, 0.9); }
    .home-text-rotator-wrapper .bx-pager-item a.active {
      background: #00c4e0; }
  .home-text-rotator-wrapper .bx-pager-item + .bx-pager-item {
    margin-left: 5px; }

.main .centre {
  margin-bottom: 3rem;
  padding: 1.5rem; }

.main .home-title {
  border-bottom: 1px solid rgba(0, 196, 224, 0.5);
  width: 100%; }
  @media only screen and (min-width: 60em) {
    .main .home-title {
      border-right: 1px solid rgba(0, 196, 224, 0.5);
      border-bottom: none;
      float: left;
      width: calc((100% - 3rem) / 3);
      margin: 0 0 0 1.5rem;
      padding: 1.5rem 1.5rem 1.5rem 0; }
      .main .home-title:nth-child(3n+1) {
        margin-left: 0; } }
  .main .home-title h1 {
    color: #44687d;
    font-family: "Catamarman", "Helvetica Neue", "Arial", sans-serif; }
  .main .home-title p {
    font-family: "Libre Baskerville", "Times New Roman", serif;
    font-size: 1.2rem;
    color: #1a2791; }
    @media only screen and (min-width: 60em) {
      .main .home-title p {
        font-size: 1.6rem; } }

.main .intro-text {
  line-height: 2;
  font-family: "Libre Baskerville", "Times New Roman", serif;
  font-size: 1.1rem; }
  @media only screen and (min-width: 60em) {
    .main .intro-text {
      float: left;
      width: 66.66667%;
      padding: 1.5rem 0 1.5rem 3rem;
      margin-bottom: 0;
      font-size: 1.2rem; } }
