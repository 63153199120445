
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#1a2791; 						// the brand colour - dark blue
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#00c4e0; 						// the accent colour - light blue
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker

$color-tertiary: 		#947dba; 						// the accent colour -light  purple
$color-tertiary-light:	lighten($color-tertiary, 5%);	// the accent colour - lighter
$color-tertiary-dark:	darken($color-tertiary, 5%);	// the accent colour - darker

$color-quaternary: 		#411787; 						// the accent colour - purple
$color-quaternary-light:lighten($color-quaternary, 5%);	// the accent colour - lighter
$color-quaternary-dark:	darken($color-quaternary, 5%);	// the accent colour - darker

$color-quinary: 		#172154; 						// the accent colour - dark blue for footer
$color-quinary-light:lighten($color-quinary, 5%);	// the accent colour - lighter
$color-quinary-dark:	darken($color-quinary, 5%);	// the accent colour - darker

$color-senary: 		#e5f9fc; 						// the accent colour - dark blue for footer
$color-senary-light:lighten($color-senary, 5%);	// the accent colour - lighter
$color-senary-dark:	darken($color-senary, 5%);	// the accent colour - darker

$color-septenary: 		#ad749c; 						// the accent colour - pink
$color-septenary-light:lighten($color-septenary, 5%);	// the accent colour - lighter
$color-septenary-dark:	darken($color-septenary, 5%);	// the accent colour - darker


$color-octonary: 		#6da6bc; 						// the accent colour -pale blue for form
$color-octonary-light:lighten($color-octonary, 5%);	// the accent colour - lighter
$color-octonary-dark:	darken($color-octonary, 5%);	// the accent colour - darker



//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;							// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#bbb;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#85898a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black


//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#44687d;								// body text colour
$color-sub:		#44687d;								// sub and intro text colour
$color-title:	$color-primary;							// heading text colour


