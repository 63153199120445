
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

.hero {

	.hero-inner {
		height: auto;
		background: url('/images/banners/home/home-1.jpg') no-repeat top center;
		background-size: auto 500px ;
		padding-top: 160px;

		@media #{$mobileXL} {
			background: url('/images/banners/home/home-1.jpg') no-repeat center;
			background-size: cover;
		}

		@media #{$tablet} {
			height: 800px;
		}

		@media #{$nav-breakpoint} {
			padding-top: 0;
		}

		> .inner {
			z-index: 2;
		}
	}

	.inner {
		@extend %clear-fix;
	}
}

.home-hero-images {
	@include coverX(absolute);
	top: 0;
	height: 100%;
	z-index: 1;

	* {
		height: 100% !important;
		width: 100% !important;
	}

	// Loop through the li's and add a background image
	$slide-images: 3;

	li {

		@for $i from 1 through $slide-images {

			&.slide-#{$i} {
				background: url('/images/banners/home/home-#{$i}.jpg') no-repeat center center;
				background-size: cover;
				transition: background .5s ease-out;
			}
		}
	}
}

.hero-circles {
	width: 100%;
	margin: 0 auto;
	padding: 2rem 0;
	@extend %clear-fix;
	padding-top: 60px;
	position: relative;

	@media #{$tabletXL} {
		top: 110px;
		padding-top: 0;
	}

	blockquote {
		color: $color-white;
		font-size: $h6;
		font-family: $font-family-serif;
		margin: ($block-margin / 2) 0;

		@media #{$tablet} {
			font-size: $h4;
		}

		@media #{$tabletXL} {
			font-size: $h2;
			padding-left: 30%;
		}

		@media #{$nav-breakpoint} {
			margin: ($block-margin * 2) 0 ($block-margin / 2);
		}

		cite {
			font-family: $font-family-body;
			@extend %uppercase-text;
			display: block;
			font-style: normal;
			font-size: 0.8rem;
			margin-top: $paragraph-margin;
		}
	}

	.circles-container {
		@extend %clear-fix;
		margin-top: $block-margin;
	}

	.circle {
		width: 100%;
		position: relative;
		top: 10%;
		transition: all .3s ease-in-out;
		display: block;
		margin-bottom: 1.5rem;
		float: left;
		height: 70px;

		@media #{$mobileXL} {
			width: 50%;
			height: auto;
			border-radius: auto;
		}

		@media #{$tablet} {
			width: 25%;
		}

		@media #{$laptop} {

			&:nth-child(1) {
				top: -80px;
			}

			&:nth-child(2) {
				top: 70px;
			}

			&:nth-child(3) {
				top: -30px;
			}

			&:nth-child(4) {
				top: 40px;
			}
		}

		a {
			position: relative;
			display: block;
			color: $color-white;
			height: 100%;
			width: 100%;
			text-align: center;
			background: $color-secondary;
			font-size: 0.7rem;
			margin: 0 auto;
			border-radius: $border-radius * 3;

			@media #{$mobileXL} {
				font-size: 0.8rem;
				@include circle(140px);
				box-shadow: $box-shadow;
			}

			@media #{$tablet} {
				font-size: 0.9rem;
				@include circle(160px);
			}

			@media #{$laptop} {
				font-size: 1.1rem;
				@include circle(220px);
			}

			&:hover {
				transform: scale(1.05);
				background: $color-septenary;
				transition: t(default);

				> span {
					text-decoration: underline;
				}
			}

			img {
				width: 16px;

				@media #{$tablet} {
					width: 23px;
				}

				@media #{$laptop} {
					width: auto;
				}
			}
		}

		span {
			@include centre;
		}
	}
}

.hero-bottom {
	.hero-services {
		margin-bottom: 0;
		display: block;

		ul {
			li {
				height: 100px;
				margin-bottom: $block-margin / 2;

				@media #{$tabletXL} {
					height: 300px;
				}

				span {
					&:after {
						width: 100%;
						height: 60px;

						@media #{$tabletXL} {
							width: 250px;
							height: 88px;
						}
					}
				}

				&:hover {
					span {
						&:after {
							width: 110%;
							height: 80px;

							@media #{$tabletXL} {
								width: 270px;
								height: 100px;
							}
						}
					}
				}
			}
		}
	}
}

.home-text-rotator-wrapper {

	.bx-controls {
		@media #{$tabletXL} {
			padding-left: 30%;
		}
	}

	// bx pagers
    .bx-pager-item {
        display: inline-block;
        width: 12px;
        height: 12px;
        overflow: hidden;
        margin-bottom: 0;
        border-radius: 50%;

        a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: $color-white;
            text-indent: -999em;
            background: rgba($color-white, 0.9);

            &.active {
                background: $color-secondary;
            }
        }

        &+.bx-pager-item {
            margin-left: 5px;
        }
    }
}

.main {
	.centre {
		@extend %elastic;
		margin-bottom: $block-margin;
		padding: $block-padding;
	}

	.home-title {
		border-bottom: $border-blue;
		width: 100%;

		@media #{$tabletXL} {
			border-right: $border-blue;
			border-bottom: none;
			@include fraction(1/3, $block-padding);
			padding: $block-padding $block-padding $block-padding 0;
		}

		h1 {
			@extend %uppercase-text;
			color: $color-text;
			font-family: $font-family-body;
		}

		p {
			font-family: $font-family-serif;
			font-size: $h5;
			color: $color-primary;

			@media #{$tabletXL} {
				font-size: $h3;
			}
		}
	}

	.intro-text {
		line-height: 2;
		font-family: $font-family-serif;
		font-size: $h6;

		@media #{$tabletXL} {
			@include fraction(2/3);
			padding: $block-padding 0 $block-padding ($block-padding * 2);
			margin-bottom: 0;
			font-size: $h5;
		}
	}
}
